import React, { useEffect, useRef, useState } from 'react';
import '../App.css';
import Pic_PCL from '../images/ProximaCentauriLLClogo3.jpeg';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';


const useFooter = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return {
    currentYear: getCurrentYear(),
  };
};

const Footer = () => {
  const { currentYear } = useFooter();
  const [isVisible, setIsVisible] = useState(false);
  const footerDivRef = useRef(null);
  const footerRef = useRef(null);
  const canvasRef = useRef(null);
  const location = useLocation();
  

  const handleScroll = () => {
    if (footerDivRef.current) {
      const elementTop = footerDivRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      // Adjust this value to control when the slide-in should start
      const triggerOffset = 100;

      if (elementTop < windowHeight - triggerOffset) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check visibility on initial render
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]);


  useEffect(() => {
    const footer = footerRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let particlesArray = [];
    const mouse = { x: 0, y: 0 };

    canvas.width = footer.offsetWidth;
    canvas.height = footer.offsetHeight;

    window.addEventListener('resize', () => {
      canvas.width = footer.offsetWidth;
      canvas.height = footer.offsetHeight;
    });

    const createParticle = (x, y) => {
      const particle = {
        x,
        y,
        size: Math.random() * 10 + 1,
        speedX: Math.random() * 2 - 1,
        speedY: Math.random() * 2 - 1,
        alpha: 1,
        life: Math.random() * 10 + 20,
      };
      particlesArray.push(particle);
    };

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      mouse.x = e.clientX - rect.left;
      mouse.y = e.clientY - rect.top;
      for (let i = 0; i < 5; i++) {
        createParticle(mouse.x, mouse.y);
      }
    };

    const handleTouchMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const touch = e.touches[0]; // Handle the first touch point
      mouse.x = touch.clientX - rect.left;
      mouse.y = touch.clientY - rect.top;
      for (let i = 0; i < 5; i++) {
        createParticle(mouse.x, mouse.y);
      }
    };

    const updateParticles = () => {
      for (let i = 0; i < particlesArray.length; i++) {
        const p = particlesArray[i];
        p.x += p.speedX;
        p.y += p.speedY;
        p.alpha -= 0.01;
        if (p.alpha <= 0) {
          particlesArray.splice(i, 1);
          i--;
        }
      }
    };

    const drawParticles = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.globalCompositeOperation = 'source-over';
      for (const p of particlesArray) {
        ctx.fillStyle = `rgba(61, 102, 234, ${p.alpha})`;
        ctx.beginPath();
        ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
        ctx.fill();
      }
    };

    const animate = () => {
      updateParticles();
      drawParticles();
      requestAnimationFrame(animate);
    };

    footer.addEventListener('mousemove', handleMouseMove);
    footer.addEventListener('touchmove', handleTouchMove); // Add touchmove event
    footer.addEventListener('touchstart', handleTouchMove); // Add touchstart event
    animate();

    return () => {
      footer.removeEventListener('mousemove', handleMouseMove);
      footer.removeEventListener('touchmove', handleTouchMove);
      footer.removeEventListener('touchstart', handleTouchMove);
    };
  }, []);



  return (
    <footer id='footer1' ref={footerRef}>
      <canvas ref={canvasRef} id="waterCanvas"></canvas>
      <div id="footerdiv1" className={`slide-in-div-footer ${isVisible ? 'visible' : ''}`}
        ref={footerDivRef}>
                <div id="proximacentauri_div">
                  <img id='proximacentauri_image' src={Pic_PCL} alt="ProximacentauriImage"/>
                </div>
                <div id="proximacentauri_header">
                  <h1>Prox Offensive InfoSec</h1>
                </div>
                <div id='footerdiv2'>
                </div>
                <div id='footerdiv3'>
                  <p>Look for us on these platforms</p>
                </div>
                <div id='footerdiv4'>
                  <div id='proximacentaurifooter_header2'> <h2>Contact Us</h2></div>
                  <div id='footer_contactinfo'>
                    <p>phone: 555-555-5555</p>
                    <p>email: proximacentauri@gmail.com</p>
                  </div>
                </div>
                <div id='footerdiv5'>
                  <a  href="/contact" style={{ textDecoration: 'none' }}>
                  <motion.div
                    id='href_footer'
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.8 }}
                    style={{ textDecoration: 'underline' }}
                    >Let's Get Started!
                </motion.div>
                </a>
                </div>
                <div id="social-icons">
                  <a id='fafacebook' href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={50} className="facebook" />
                  </a>
                  <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={50} className="instagram" />
                  </a>
                  <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={50} className="linkedin" />
                  </a>
                </div>
            <div id='copyright'>
                <p>Copyright © {currentYear} Proxima Centauri Productions LLC & <a id='href_contact' href="https://yokaisoftware.com/">Yokai Software</a></p>
            </div> 
      </div>
    </footer>
  );
};

export default Footer;






/*import React from 'react';
import '../App.css';

const useFooter = () => {
  const getCurrentYear = () => new Date().getFullYear();

  return {
    currentYear: getCurrentYear(),
  };
};

const Footer = () => {
  const { currentYear } = useFooter();

  return (
    <footer id='footer1'>
      <p>Copyright © {currentYear} Proxima Cenntauri</p>
    </footer>
  );
};

export default Footer;
*/