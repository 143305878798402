import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Nav = styled.nav`
background: transparent;
height: 85px;
display: flex;
justify-content: space-between;
padding: 0.2rem calc((100vw - 1000px) / 2);
z-index: 100;
position: fixed; 
top: 0;
font-size: 1.4rem;
width: 100%;

@media screen and (max-width: 1080px) {
  display: none;
  &.active {
    display: flex;
    width: 100%;
    animation: fadeInDown 0.5s ease-in-out forwards;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
}
`;


export const NavLink = styled(Link)`
position: relative;
color: black;
font-weight: bold;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 2.8rem;
cursor: pointer;

@media screen and (max-width:1080px) {
  padding-top: 30%;
  height: -webkit-fit-content;
  height:fit-content;
  width: -moz-fit-content;
  width:fit-content;
  margin-left: 10%;
  padding: 8%;
}

@media screen and (max-width:932px) and (orientation: landscape){
  padding: 3%;
}

@media screen and (max-width:926px) and (orientation: landscape){
  padding: 3%;
}

&.active {color: rgb(151, 5, 19);}
${props =>
    props.withShadow &&
    css`
      text-shadow: 
        -1px -1px 0 #000,  
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
    `}
  
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-right: 30rem;
/* Second Nav */
/*margin-right: 24px;*/ 
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media only screen and (max-width: 1440px) {
margin-right: 10rem;
}
@media screen and (max-width: 1080px) {
	display: none;
  width: 60%;
  padding-bottom: 10%;
  margin-left: 10%;
  padding-left: 27%;
  padding-right: 30%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  &.active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: blue;
    animation: fadeInDown 0.5s ease-in-out forwards;
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-15px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
`;