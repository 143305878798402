import React from 'react';
import { CookiesProvider } from 'react-cookie';
import './App.css';
import Navbar from './components/navbar.js';
import { BrowserRouter as Router, Routes, Route }
	from 'react-router-dom';
import Home from './pages/index.js';
import Blogs from './pages/blogs.js';
import Info from './pages/info.js';
import SignUp from './pages/signup.js';
import Contact from './pages/contact.js';
import Secure from './pages/secure.js';
import { useState, useEffect } from 'react'



function App() {
  

  const [isVisible, setIsVisible] = useState(false);


  const [loggedIn, setLoggedIn] = useState(() => {
    const storedToken = localStorage.getItem('accessToken');
    return !!storedToken;
});

const [username, setUsername] = useState(() => {
  const storedUsername = localStorage.getItem('username');
  return storedUsername || ''; // Set the username to an empty string if it's not present
});



  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) { // Adjust this value as per your requirement
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

	return (
    <CookiesProvider>
		<Router>
			<Navbar />
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/blogs' element={<Blogs />} />
				<Route path='/sign-up' element={<SignUp setLoggedIn={setLoggedIn} setUsername={setUsername}  loggedIn={loggedIn} username={username}/>} />
        <Route path='/info' element={<Info />} />
        <Route path='/secure' element={<Secure />} />
			</Routes>
		</Router>
    {isVisible && (
        <button className="to-top-btn" onClick={scrollToTop}>
          Top 
        </button>
      )}
    </CookiesProvider>
	);
}

export default App;



//import logo from './logo.svg';
//import './App.css';

//function App() {
  //return (
    //<div className="App">
      //<header className="App-header">
        //<img src={logo} className="App-logo" alt="logo" />
        //<p>
          //Edit <code>src/App.js</code> and save to reload.
       // </p>
        //<a
         // className="App-link"
          //href="https://reactjs.org"
          //target="_blank"
          //rel="noopener noreferrer"
        //>
          //Learn React
        //</a>
      //</header>
    //</div>
  //);
//}

//export default App;
