//import { Transition } from '@headlessui/react';
//import React, { useEffect, useState } from "react";
import React, { useEffect, useRef, useState } from 'react';




const SlideInDiv = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const slideInDivRef = useRef(null);

  const handleScroll = () => {
    if (slideInDivRef.current) {
      const elementTop = slideInDivRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (elementTop < windowHeight) {
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`slide-in-div ${isVisible ? 'visible' : ''}`}
      ref={slideInDivRef}
    >
      {children}
    </div>
  );
};

export default SlideInDiv;






/*const Slider = () => {



const Props {
    show?: boolean;
    children: React.ReactNode;
  }export default function SlideUp(props: Props) {
    const { show, children } = props;
    return (
      <Transition
        show={show}
        enter="transform transition ease-out duration-500"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform transition ease-out duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
      >
        {children}
      </Transition>
    );
  }

}

export default Slider;*/