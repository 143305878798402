import React, { useEffect, useState } from 'react';
//import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent, options = {}) => {
  //return (props) => {
    //const navigate = useNavigate();
    //const [loggedIn, setLoggedIn] = useState(false);
    return (props) => {
      
      const [authData, setAuthData] = useState({
        loggedIn: false,
        username: null,
      });

      useEffect(() => {
        const checkAuth = async () => {
          const token = localStorage.getItem('accessToken');
          const username = localStorage.getItem('username');
  
          if (token) {
            setAuthData({
              loggedIn: true,
              username: username,
            });
          } else {
            setAuthData({
              loggedIn: false,
              username: null,
            });
          }
        };
  
        checkAuth();
      }, []); // Run this effect only once on mount
  
      return <WrappedComponent {...props} authData={authData} />;
    };
  };
   
export default withAuth;



 /*useEffect(() => {
      // Check if the user is authenticated
      const token = localStorage.getItem('accessToken');
      const username = localStorage.getItem('username');
      //console.log('Token:', token);
      console.log('Token in withAuth:', token);
      console.log('username in withAuth:', username);

      //if (!token && options.redirect) {
        if (!token) {
        //console.log('Redirecting to /sign-up');
        // Redirect to login page if not authenticated
        //navigate('/sign-up');
        setAuthData({
          loggedIn: false,
          username: null,
        });
      }
      else {
         // Set the username and authentication token when the user logs in
         setAuthData({
          loggedIn: true,
          username: username,
        });
      }
    }, [props.loggedIn, props.username]); // Add props.loggedIn as a dependency

    // Render the wrapped component with props
    return <WrappedComponent {...props} authData={authData} />;
  };
};*/




/*import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      // Check if the user is authenticated
      const token = localStorage.getItem('accessToken');
      console.log('Token:', token);

      if (!token) {
        console.log('Redirecting to /sign-up');
        // Redirect to login page if not authenticated
        navigate('/sign-up');
      }
    }, [navigate]);

    // Render the wrapped component with props
    return <WrappedComponent {...props} />;
  };
};

export default withAuth;*/
