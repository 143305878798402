import React, { useRef, useEffect } from 'react';
import '../App.css';
import Pic14 from '../images/pic_14.jpg';
import Footer from './footer.js';

const Info = () => {

	const infoBox2Ref = useRef(null);
	const infoBox3Ref = useRef(null);
	const infoBox4Ref = useRef(null);

	useEffect(() => {
		// Scroll to the top when the component mounts
		window.scrollTo(0, 0);
	  }, []); // Empty dependency array ensures this effect runs only once
	

	useEffect(() => {
		const hash = window.location.hash.substring(1);
	
		if (hash === 'info_box2' && infoBox2Ref.current) {
		  scrollToElement(infoBox2Ref.current);
		} else if (hash === 'info_box3' && infoBox3Ref.current) {
		  scrollToElement(infoBox3Ref.current);
		} else if (hash === 'info_box4' && infoBox4Ref.current) {
		  scrollToElement(infoBox4Ref.current);
		}
	  }, []);
	
	  const scrollToElement = (element) => {
		const elementRect = element.getBoundingClientRect();
		const offsetTop = elementRect.top + window.scrollY;
		const windowHeight = window.innerHeight;
		const elementHeight = elementRect.height;
		const scrollToY = offsetTop - (windowHeight - elementHeight) / 2;
		//const scrollToY = offsetTop - (windowHeight - elementRect.height) / 2;
		window.scrollTo({ top: scrollToY, behavior: 'smooth' });
	  };
	

	return (
		<div id="infodiv_1">
			<div id='info_picdiv'>
				<img id='pic_14' src={Pic14} alt="Pic14" />
			</div>
			<div id='info_box1'>
				<h1>Resource Nexus</h1>
			</div>
			<div id='info_div_container'>
			<div id='info_box2' ref={infoBox2Ref}>
				<h2>Penetration Testing</h2>

				<p> An Infrastructure Penetration Test uncovers vulnerabilities 
					residing within your infrastructure and provides a detailed attack narrative 
					to help evaluate the impacts of each finding in a comprehensive report.

					What you'll get: A thorough assesment of networks and systems. Identification
					of all paths to Domain Admin. System hardening recommendations. OS and third-party
					patching assessment. Identify insecure configutations within on premises and cloud
					environments. Uncover the impacts of techniques, tactics and procedures commonly used
					by threat actors. All in a comprehensive report with detailed findings 
					and remediation steps</p>
			</div>
			<div id='info_box3' ref={infoBox3Ref}>
				<h2>Application Security Testing</h2>
				<p>Reduce The Risk Of A Breach Within Your Web Apps, Mobile Apps and APIs
					An Application Pentest is a thorough review of custom-developed web apps,
					mobile apps, and APIs leveraging an industry-leading OWASP-based methodology. 
					During this assessment, our team of certified ethical hackers systematically 
					maps and tests for vulnerabilities.
					We preform thorough mapping of in-scope pages and endpoints.
					Utilize automated scanning and evaluate validation of findings.
					Apply manual discovery of up to 200 types of vulnerabilities across 10+ categories.
					We will provide demonstrated impact to help with executive and developer buy-in.
					All included in a comprehensive report with detailed step-by-step instructions to reproduce findings.
					Also, advisory on remediation steps and retesting to validate closure of findings.</p>
			</div>
			<div id='info_box4' ref={infoBox4Ref}>
				<h2>Mentoring</h2>
				<p>Expert Guidance: Our professionals have had hands on experience with real-world threats and we’re ready to share our knowledge with you!
					Hands-On Learning: Mentorship isn’t just about theory, it’s about practical skills. Expect engaging labs, simulated scenarios, and hands-on challenges that mirror actual cyber threats.
					Tailored Curriculum: Whether you’re interested in penetration testing, secure coding, or network architecture, we’ll customize the mentorship to match your goals.
					Career Boost: Join our network and open doors to exciting opportunities.
					Ethical Mindset: We emphasize ethical hacking, responsible disclosure, and community contributions. Be part of a movement that protects digital landscapes.
					Ready to Level Up?
					
					</p>
			</div>
			</div>
			<Footer/>
		</div>
	);
};

export default Info;

//<h1> Information on special topics </h1>

/*A Cyber Maturity Assessment provides a health check that 
					evaluates the security within a business and ultimately provides 
					a security road map. A security road map will then strengthen 
					business security posture and is the first step to becoming 
					compliant and achieving contractual, regulatory, and internal 
					stakeholder requirements.
					Contact Us
					This is the infographic for the Cyber Maturity Assessment Infographic
					Why conduct a Cyber Maturity Assessment?
					Ensure cybersecurity is compliant with contractual requirements.
					Straightforward, easy-to-read assessment with no IT jargon
					Actionable recommendations to strengthen compliance and exceed customer expectations
					1:1 consultation with an ethical hacker, providing on-demand support to gain further insight and support achievement of desired results*/
