import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';


const SignUp = ({ closeModal, setLoggedIn, loggedInUser, setLoggedInUser, setUsername: setParentUsername}) => {
  console.log('Signup Component - loggedInUser:', loggedInUser)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['accessToken']);


  // Determine the API URL based on the environment
  const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_LOCAL;



  // New useEffect for handling automatic logout
  useEffect(() => {
    const handleBeforeUnload = async () => {
      try {
        // Implement your logout logic here
        await handleLogout();
      } catch (error) {
        console.error('Error during logout:', error);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      console.log('Component is unmounted. Cleaning up.');
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  

  

  const handleRegister = async () => {
    try {
      await axios.post(`${apiUrl}/api/register`, { username, password });
      console.log('Registration successful');
      window.alert('Registration successful');
      setUsername(''); // Clear input field
      setPassword(''); // Clear input field
      closeModal();
    } catch (error) {
      console.error('Registration failed:', error.response.data.message);
      window.alert('Registration failed');
    }
  };

  const handleLogin = async (source) => {

    console.log('Handling login...'); 
    console.log('Handling login from:', source);

    try {
      //const response = await axios.post('http://localhost:5001/api/login', { username, password });
      //const { username: loggedInUser } = response.data; // Extract the username from the response
      const response = await axios.post(`${apiUrl}/api/login`, { username, password });
      const { username: loggedInUser, accessToken } = response.data; // Extract the username and token from the response
      
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

      
      console.log('Received accessToken in login func:', accessToken);


      // Set the username and authentication token when the user logs in
      setLoggedIn(loggedInUser);
      setUsername(loggedInUser);
      
      
      
      

      // Store the authentication token in local storage
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('username', loggedInUser);

      console.log('Token before making the request in login:', accessToken);

      window.dispatchEvent(new Event('storage'));


      console.log(`Login successful. Welcome, ${loggedInUser}!`);
      window.alert(`Login successful. Welcome, ${loggedInUser}!`);
      setUsername(''); // Clear input field
      setPassword(''); // Clear input field
      if (source === 'page' && closeModal) {
        console.log('Closing modal...');
        closeModal();
      }
      
      navigate('/secure');

  
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data.message : error.message);
      window.alert('Login failed');
    }
    console.log('Signup Component 2 - loggedInUser:', loggedInUser)
    // Assuming you receive a token from the server upon successful login
    const token = 'your_received_token';

    // Set the token in a cookie
    setCookie('accessToken', token, { path: '/' });
    
  };
  
  useEffect(() => {
    // This cleanup function will be called when the component is unmounted
    return () => {
      console.log('Component is unmounted. Cleaning up.'); // Add this line
      // Cancel any ongoing asynchronous operations, clear timeouts, etc.
    };
  }, []);

  
  const handleLogout = async () => {
    console.log('setLoggedIn:', setLoggedIn);

    // Check if the component is still mounted
    if (!setLoggedIn) {
      console.log('Component is unmounted. Skipping logout.'); // Add this line
      return;
    }


    console.log('Handling logout...');
    console.log('handleLogout function called');
    //let response; // Declare response variable

    try {
      // Perform logout operation using API
      //await axios.post('http://localhost:5001/api/logout');
      await axios.post(`${apiUrl}/api/logout?` + Date.now());
      //response = await axios.post('http://localhost:5001/api/logout?' + Date.now());

      //console.log('Logout response:', response.data); // Add this line
      //console.log(`Logout successful. Goodbye, ${loggedIn}!`);
      //window.alert(`Logout successful. Goodbye, ${loggedIn}!`);
      

      // Remove the authentication token from local storage
      console.log('Token before clearing local storage:', localStorage.getItem('accessToken'));
      // ... rest of the function

      delete axios.defaults.headers.common['Authorization'];

      // Clear Axios defaults
      console.log('Clearing local storage...');
      console.log('Local Storage before removing:', localStorage.getItem('accessToken'));
      localStorage.removeItem('accessToken');
      console.log('Local Storage after removing:', localStorage.getItem('accessToken'));
      
     

      setLoggedIn(false); // Set loggedIn to false
      setLoggedInUser(null); // Set loggedInUser to null
      //setLoggedIn(null);
      
      // Update the loggedIn state in the parent component
      //if (typeof setLoggedIn === 'function') {
        //setLoggedIn(null); // Set the loggedIn state to null or an appropriate value
      //}

      

      //window.location.reload();

      console.log(`Logout successful. Goodbye, ${loggedInUser}!`); // Change loggedIn to loggedInUser
      window.alert(`Logout successful. Goodbye, ${loggedInUser}!`);
      // Remove the accessToken cookie
      removeCookie('accessToken', { path: '/' }); // Add this line

      setUsername(''); // Clear input field
      setPassword(''); // Clear input field
      closeModal();
      

    } catch (error) {
      window.alert('Logout failed');
      console.error('Logout failed:', error.response ? error.response.data.message : error.message);
    }
  };
  
  
  console.log('Local Storage after logout:', localStorage.getItem('accessToken'));
  console.log('logged in user:',loggedInUser);
  
  return (
    <div id="registration_form_div">
        <div id='login_div'>
        <div id='login_heading'>
          <h1>Login and Registration</h1>
        </div>
        <div id='label_contianer'>
        <label className='label'>
          Username:
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </label>
        <br></br>
        <label className='label'>
          Password:
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </label>
        </div>
        <div id='button_contianer'>
          <button className='button_login' onClick={handleRegister}>Register</button>
          <button className='button_login' onClick={() => handleLogin('page')}>Login</button>
          <button className='button_login' onClick={handleLogout}>Logout</button>
        </div>
        </div>
      
      
    </div>
  );
}

export default SignUp;


//<button onClick={handleLogin} Id='modal_button'>Login for modal</button>





/*import React from 'react';

const SignUp = () => {
	return (
		<div>
			<h1>Sign Up Successful</h1>
		</div>
	);
};

export default SignUp;*/

/*const handleLogin = async () => {
    try {
      await axios.post('http://localhost:5001/api/login', { username, password });
      setLoggedIn(true);
      console.log('Login successful');
      window.alert('Login successful');
      setUsername(''); // Clear input field
      setPassword(''); // Clear input field
      closeModal();
    } catch (error) {
      console.error('Login failed:', error.response.data.message);
      window.alert('Login failed');
    }
  };

  const handleLogout = async () => {
    try {
      // Perform logout operation using API
      console.log(`Logout successful. Goodbye, ${loggedIn}!`);
      window.alert(`Logout successful. Goodbye, ${loggedIn}!`);
      setUsername(''); // Clear input field
      setPassword(''); // Clear input field
      closeModal();
    } catch (error) {
      window.alert('Logout failed');
      console.error('Logout failed:', error.response ? error.response.data.message : error.message);
    }
  };*/


