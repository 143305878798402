import React, { useEffect } from 'react';
//import React, { useState } from "react";
//import ReactDOM from "react-dom";
import '../App.css';
//import FadeInOut from "./FadeInOut";
import ContactForm from './ContactForm.js'
import Pic16 from '../images/pic_16.jpg';
import Footer from './footer.js'

const Contact = () => {

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once

  return (
		<div id='contact_div1'>
      <div id='contact_div_inside'>
          <div id='contact_picdiv'>
            <img id='pic_contact' src={Pic16} alt="Pic16" />
          </div>
        <div id='contact_textdiv' className="outline-text">
          <h1>Let's Connect</h1>
        </div>
        <div id='contact_textdiv2' className="outline-text">
          <p>Cyber threats don't wait, neither should you!
          Let's safeguard your assets, connect with us!
          </p>
          <p>contact us at</p>
          <p id='email_contact'>Contact@proxoffensive.com</p>
          <p>or reach us here</p>
        </div>  
      </div>
      
      <div id='contact_div2'>
			  
        <div id='contactform_div'>
          <ContactForm/>
        </div>
      </div>
      <Footer/>
		</div>
	);
};
 

export default Contact;




/*const Contact = () => {
	return (
		<div>
			<h1>Mail us on feedback@geeksforgeeks.org</h1>
		</div>
	);
};

 const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const extraStyles = {
    // position: "fixed",
    // top: "30px",
    // left: 0,
    // right: 0,
    // bottom: 0,
    // background: "rgba(0, 0, 0, 0.4)",
    // color: "#FFF"
  };
  return (
    <div className="test_fade">
      <button onClick={toggleShow}>{show ? "hide" : "show"}</button>
      <FadeInOut show={show} duration={800} style={extraStyles}>
        I'm in a fade in AND out transition and I am only mounted when visible
        and automatically unmounted when my fade out ends!
      </FadeInOut>
    </div>
  );
}

export default Contact;*/