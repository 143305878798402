//import React, { useState, useEffect } from 'react'
//import PropTypes from 'prop-types'
import '../App.css'
import { Transition } from 'react-transition-group';
import { useRef } from 'react';

const duration = 500;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 1 },
  exited:  { opacity: 1 },
};

const Fade = ({ in: inProp, children,
    wrapperElement = "div"}) => {
    
  const nodeRef = useRef(null);
  const Component = wrapperElement;
  
  return (
    <Transition nodeRef={nodeRef} in={inProp}>
      {state => (
          <Component ref={nodeRef} style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}> {children}
          </Component>

      )}
    </Transition>
  );
}

export default Fade;

/*const Fader = ({ }) => {

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    useEffect(() => {

    })





}*/


/*const Fader = ({ text }) => {

    const [fadeProp, setFadeProp] = useState({
        fade: 'fade-in',
    });

    useEffect(() => {
        const timeout = setInterval(() => {
            if (fadeProp.fade === 'fade-in') {
                setFadeProp({
                    fade: 'fade-out'
                })
            } else {
                setFadeProp({
                    fade: 'fade-in'
                })
            }
        }, 4000);

        return () => clearInterval(timeout)
    }, [fadeProp])

    return (
        <>
            <h1 data-testid="fader" className={fadeProp.fade}>{text}</h1>
        </>
    )
}

Fader.defaultProps = {
    text: 'Hello World!'
}

Fader.propTypes = {
    text: PropTypes.string,
}

export default Fader*/