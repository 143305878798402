// useScrollBehavior.js
import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

export const useScrollBehavior = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    // Restore scroll position if available
    const savedScrollPosition = sessionStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
    }

    const handleBeforeUnload = () => {
      // Save the current scroll position before unloading the page
      sessionStorage.setItem('scrollPosition', window.scrollY);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (navigationType === 'PUSH') {
      // On new navigation, scroll to the top
      window.scrollTo(0, 0);
    } else if (navigationType === 'POP') {
      // On back/forward navigation, restore scroll position
      const savedScrollPosition = sessionStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }
    }
  }, [location, navigationType]);
};
