//import React from 'react';
import '../App.css';
import React, {useState} from 'react';
import './index.js';
//import FadeInAnimation from "./FadeInAnimation";
import './Fader.js'
//import FadeInOut from "./FadeInOut";
import Fade from './Fade.js'
import { motion } from 'framer-motion';



const Slideshow = () => {
	const [active, setActive] = useState(0);
	const [show, setShow] = useState(false);
	
	const Slide = ({ text, caption, active }) => {
		return (
		<div id='myLogo'>
			<div className={`slide ${active ? "active" : ""}`}>
			<div id="slideDiv" alt={caption}>{text}</div>
			<span>{caption}</span>
		  </div>

		</div>
		   	  
		);
	  };

	  const Slider = ({ images }) => {
		const [active, setActive] = useState(0);
	  
		/*const onNext = () => {
		  if (active < images.length - 1) {
			setActive(active + 1);
		  }
		};
	  
		const onPrev = () => {
		  if (active > 0) {
			setActive(active - 1);
		  }
		};*/
		
		const items = [
			{ id: 1, text: 'Infrastructure Pen Testing' },
			{ id: 2, text: 'Vulnerability Testing' },
			{ id: 3, text: 'Application Security Testing' },
			{ id: 4, text: 'CyberSecurity Mentorship' }
		  ];


		return (
		<div id='slideshow_maindiv'>
			<div id="slideshowlist">
				{items.map((item, index) => (
        			<motion.div 
          				key={item.id}
          				id={index === 0 ? 'odd_list_element' : undefined}
          				className={`anchor dot active list_element ${item.id === 2 ? 'custom-style' : ''}`}
          				data-anchor={item.id}
          				onClick={() => { setActive(index); setShow(show) }}
          				whileHover={{ scale: 1.05 }}
          				whileTap={{ scale: 0.4 }}
          				
        			>
          			<span className="line"></span>
          				{item.text}
        			</motion.div>
      			))}
                
			</div>
		  <div className="slider">
			<div className="slides">
			  {images.map((e, i) => (
				<Slide key={e.caption} {...e} active={i === active} />
			  ))}
			</div>
			
		  </div>
		</div>	  
		);
	  };

	  
		const images = [
		  {
			text: <Fade show={!show}>
				
					<div id="image_div1"className='imagedivs's>
						<h3>Infrastructure Penetration Testing</h3>
						<h4>Find weaknesses others overlook</h4>
						<p>An infrastructure penetration testing assessment uncovers vulnerabilities residing in your IT and network systems and provides a tailored approach to each environment. 
							We utilize both automated and manual research techniques to discover weaknesses in our client's security posture and educate them on how to remediate those weaknesses.
						</p>
			      </div>
				  </Fade> ,
			
		  },
		  {
			text: <Fade show={!show}>
			<div id="image_div2"className='imagedivs'>
						<h3>Vulnerability Testing</h3>
						<p>Vulnerability Testing involves assessing the susceptibility 
							of a target to potential attacks. It encompasses both automated 
							scans and manual assessments. Automated tools scan for known vulnerabilities, 
							such as outdated software or misconfigurations. Meanwhile, security experts 
							manually analyze systems to uncover less obvious weaknesses. The benefits 
							of vulnerability testing include early detection, improved risk management, 
							compliance with security standards, and overall enhanced security.</p>
	 			 </div> 
				</Fade> ,
			  
		  },
		  {
			text: <Fade show={!show}>
			<div id="image_div3">
						<h3>Application Security Testing</h3>
						<p>Reduce the risk of a breach within your application
						   More targeted in scope than a regular pentest, application 
						   security testing uncovers vulnerabilities residing in your 
						   web and mobile apps. Application Security Testing actively 
						   explores your application from an attacker’s perspective. </p>
	  			 </div>
				   </Fade>,
			
		  },
		  {
			text: <Fade show={!show}>
			<div id="image_div4">
						<h3>Cyber Security Mentorship</h3>
						<p> Unlock your potential in offensive cybersecurity with 
							our specialized mentorship program. Designed for aspiring 
							penetration testers, our program offers hands-on guidance 
							from experienced ethical hackers. You'll gain practical 
							skills in identifying vulnerabilities, conducting penetration 
							tests, and staying ahead of emerging threats. Whether you're 
							looking to sharpen your skills or start a career in cybersecurity, 
							our mentorship provides the tools and expertise to excel in the 
							dynamic field of offensive security.</p>
	  			 </div> 
				   </Fade>,
				   
			
		  }
		];

		
		return (
		  <div className="slide_show">
			<Slider images={images} />
		  </div>
		);
	  //}



		
}

export default Slideshow;

/*const Slideshow = () => {

    const showSlides = (n) => {
   
        var slideIndex = 1;
        showSlides(slideIndex);
    

     // Thumbnail image controls
        const CurrentSlide = (n) =>  {
	    showSlides(slideIndex = n);
     }
	  var i;
	  var slides = document.getElementsByClassName('slide');
	  var dots = document.getElementsByClassName("anchor");
	  if (n > slides.length) {slideIndex = 1}
	  if (n < 1) {slideIndex = slides.length}
	  for (i = 0; i < slides.length; i++) {
		slides[i].style.display = "none";
	  }
	  for (i = 0; i < dots.length; i++) {
		dots[i].className = dots[i].className.replace(" active", "");
	  }
	  slides[slideIndex-1].style.display = "block";
	  dots[slideIndex-1].className += " active";

      

  
    

      return(
	  
<div>
      <div id="slideshowlist">
				
                <div className="anchor dot approach-name text-md toggle" data-anchor="1" onClick={CurrentSlide(1)}><span classnName="line"></span>FullStack Development</div>
                    
              <div className="anchor dot approach-name text-md toggle" data-anchor="2" onClick={CurrentSlide(2)}><span className="line"></span>Web Design & Development</div>
                    
                <div className="anchor dot approach-name text-md toggle" data-anchor="3" onClick={CurrentSlide(3)}><span className="line"></span>Mobile-Responsive</div>
                    
                <div className="anchor dot approach-name text-md toggle" data-anchor="4" onClick={CurrentSlide(4)}><span className="line"></span>UX Design</div>
        </div>
		<div id='slideshowbox'>
            
			
				<div className="slide" data-slide='1'>
						<p>This is a damn slide</p>
					</div>
					<div className="slide" data-slide='2'>
						<p>This is another damn slide</p>
					</div>
					<div className="slide" data-slide='3'>
						<p>And yet another damn slide</p>
					</div>
					<div className="slide" data-slide='4'>
						<p>Please stop with the damn slides</p>
					</div>
				
		</div>
          
    </div>
    )

	} 

}



export default Slideshow;*/
/*let mylogo = document.getElementById('myLogo');
	document.getElementsByClassName('anchor').onclick = function(){
  	mylogo.classList.toggle('fade');
	console.log('#myLogo')
	} 
	
	/*slideshow parts*/
	/*<div className="navigation">
			  <div class="navigation-bottom">
				{images.map((e, i) => (
				  <div
					className={`dots ${i === active ? "active" : ""}`}
					key={e.caption}
					onClick={() => setActive(i)}
				  />
				))}
			  </div>
			  <div className="navigation-next-prev">
				<div class="next-prev prev" onClick={onPrev}>
				  {" "}
				  &lt;{" "}
				</div>
				<div class="next-prev next" onClick={onNext}>
				  {" "}
				  &gt;{" "}
				</div>
			  </div>
			</div>
	
	
	
	*/

	/*<div Id='odd_list_element' className={"anchor dot active list_element"} data-anchor="1" onClick={() => {setActive(0); setShow(show)}}><span classnName="line"></span>Infrastructure Penetration Testing</div>
                    
              	<div className={"anchor dot active list_element"} data-anchor="2" onClick={() => {setActive(1); setShow(show)}}><span className="line"></span>Cyber Maturity Assessment</div>
                    
                <div className={"anchor dot active list_element"} data-anchor="3" onClick={() => {setActive(2); setShow(show)}}><span className="line"></span>Application Security Testing</div>
                    
                <div className={"anchor dot active list_element"} data-anchor="4" onClick={() => {setActive(3); setShow(show)}}><span className="line"></span>Cyber Security Mentorship</div>
				*/

//style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}				