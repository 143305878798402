import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import Modal from 'react-modal';
import withAuth from './withAuth';
import Pic15 from '../images/pic_15.jpg';
import Footer from './footer.js';
import DOMPurify from 'dompurify';

const Blogs = ( { authData } ) => {
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState('');
  const [newPostImage, setNewPostImage] = useState(null);
  const [editPostId, setEditPostId] = useState(null);
  const [editPostContent, setEditPostContent] = useState('');
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [newPostTitle, setNewPostTitle] = useState('');
  const [editPostTitle, setEditPostTitle] = useState('');
  //const [loggedIn, setLoggedIn] = useState(localStorage.getItem('accessToken') !== null);
  //const [username, setUsername] = useState('');
  const { loggedIn, username } = authData;
  console.log('authData:', authData);
  localStorage.getItem('accessToken');
  //console.log('Blogs Component - loggedInUser:', loggedInUser);
  // Modal state and functions
  const [modalIsOpen, setModalIsOpen] = useState(false);

  console.log('All Environment Variables:', process.env);
  console.log('All Environment Variables:', process.env);


  // Determine the API URL based on the environment
  const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_LOCAL;

  console.log("API URL in Blogs component:", apiUrl);


  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this effect runs only once


  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {

   // if (loggedIn) {
      fetchPosts();
    
  }, []);

    //if (loggedInUser && loggedInUser.username) {
      //setUsername(loggedInUser.username);
    //} else {
      //setUsername(''); // Set to an empty string or handle the default value accordingly
    //}
    // Fetch blog posts when the component mounts
    //fetchPosts();
  //}, [loggedInUser]);

  const fetchPosts = async () => {
    try {
      console.log('API URL:', process.env.REACT_APP_API_URL);

      console.log("API URL:", apiUrl);
      const response = await axios.get(`${apiUrl}/api/posts`);
      console.log("API URL after call:", `${apiUrl}/api/posts`);
     
      console.log('Fetched Posts:', response.data); 
      console.log('Type of response data:', Array.isArray(response.data)); // Add this line

      setPosts(response.data); // Set posts to the response data directly
    } catch (error) {
      console.error('Error fetching posts:', error.message);
    }
  };

  
  //console.log('response data usernamer:', username);
  

  const handlePost = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newPostTitle);  
      formData.append('content', newPostContent);
      formData.append('image', newPostImage);

      await axios.post(`${apiUrl}/api/posts`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      // Clear the form fields after successful post
      setNewPostTitle('');  // Add this line
      setNewPostContent('');
      setNewPostImage(null);

      // Refresh the posts
      fetchPosts();
      //setLoggedIn(true);
      closeModal(); // Close the modal
    } catch (error) {
      console.error('Error posting:', error.message);
    }
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`${apiUrl}/api/posts/${postId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      // Refresh the posts
      fetchPosts();
    } catch (error) {
      console.error('Error deleting post:', error.message);
    }
  };

  const handleEdit = (postId, postContent, postTitle) => {
    setEditPostId(postId);
    setEditPostContent(postContent);
    setEditPostTitle(postTitle);
    setEditModalIsOpen(true);
  };

  const handleUpdate = async () => {
    console.log('editPostId:', editPostId);
    console.log('editPostContent:', editPostContent);
    console.log('editPostTitle:', editPostTitle);
    
    try {
      await axios.put(
        `${apiUrl}/api/posts/${editPostId}`,
        { title: editPostTitle, content: editPostContent },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        }
      );

      // Close the edit modal
      
      setEditModalIsOpen(false);

      // Refresh the posts
      fetchPosts();
    } catch (error) {
      console.error('Error updating post:', error.message);
    }
  };
  console.log(loggedIn)
  //console.log('loggedInUser:', loggedInUser);

  return (
    <div id='blog1'>
      <div id='blog_picdiv'>
        <img id='pic_15' src={Pic15} alt="Pic15" />
      </div>
      <div id="main_blog_div">
      
      <h1 id="blog_header">{loggedIn ? `Welcome, ${username}` : 'Enjoy our Blog'}</h1>
      {loggedIn && (
        <div>
          <button onClick={openModal}>Create Post</button>
        </div>
      )}
      <div>
        
      {posts.slice().reverse().map((post) => (
          <div key={post.id}>
            <h1 id='post-title'>{post.title}</h1>
           
            {console.log('Image Path:', post.image_path)} {/* Add this line for logging */}
            {post.image_path && (
              <div>
                
                <img
                  id="post_image"
                  src={`${apiUrl}/${post.image_path}`}
                  alt="Post"
                />
                <div id='text_blog'>
                      <pre dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}></pre>
                </div>

              </div>
              
            )}
            {loggedIn && (
              <>
                <p>Image Path: {post.image_path}</p>
                <button onClick={() => handleEdit(post.id, post.content, post.title)}>
                  Edit
                </button>
                <button onClick={() => handleDelete(post.id)}>Delete</button>
              </>
            )}
          </div>
        ))}
      </div>
      {/* Post Modal */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <h3>Create a New Post</h3>
        <input
          type="text"
          value={newPostTitle}
          onChange={(e) => setNewPostTitle(e.target.value)}
        />
        <textarea
          placeholder="Write your post here..."
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewPostImage(e.target.files[0])}
        />
        <button onClick={handlePost}>Post</button>
        <button onClick={closeModal}>Close</button>
      </Modal>
      {/* Edit Modal */}
      <Modal isOpen={editModalIsOpen} onRequestClose={() => setEditModalIsOpen(false)}>
        <h3>Edit Post</h3>
        <textarea
          value={editPostContent}
          onChange={(e) => setEditPostContent(e.target.value)}
        />
        <button onClick={handleUpdate}>Update</button>
      </Modal>
    </div>
    <Footer/>
    </div>
  );
};
  
export default withAuth(Blogs);



/*{posts.map((post) => (
          <div key={post.id}>
            <p>{post.content}</p>
            {post.imagePath && <img src={`http://localhost:5001/${post.imagePath}`} alt="Post" />} */


//<img src={post.imageUrl} alt="Post" />


/*return (
    <div id="main_blog_div">
      <h2>Welcome, {loggedIn}!</h2>
      {loggedIn && (
      <div>
        <h3>Create a New Post</h3>

        <input
        type="text"
        value={newPostTitle}
        onChange={(e) => setNewPostTitle(e.target.value)}
        />
        <textarea
          placeholder="Write your post here..."
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewPostImage(e.target.files[0])}
        />
        <button onClick={handlePost}>Post</button>
      </div>
      )}
      <div>
        <h3>Recent Posts</h3>
        {posts.map((post) => (
           <div key={post.id}>
            <p>{post.content}</p>
          //{console.log('Image Path:', post.image_path)} {/* Add this line for logging *///}
          /*{post.image_path && (
            <div>
              <p>Image Path: {post.image_path}</p>
                <img id='post_image' src={`http://localhost:5001/${post.image_path}`} alt="Post" />
          </div>
        )}
        {loggedIn && (
              <>
            <button onClick={() => handleEdit(post.id, post.content, post.title)}>Edit</button>
            <button onClick={() => handleDelete(post.id)}>Delete</button>

            </>
            )}
          </div>
        ))}
      </div>
      <Modal isOpen={editModalIsOpen} onRequestClose={() => setEditModalIsOpen(false)}>
        <h3>Edit Post</h3>
        <textarea
          value={editPostContent}
          onChange={(e) => setEditPostContent(e.target.value)}
        />
        <button onClick={handleUpdate}>Update</button>
      </Modal>
    </div>
  );
};*/








/*import React from 'react';
import '../App.css';




const Blogs = () => {
	return (
		<div id='blog_div1'>
		<h1>You can write your blogs!</h1>
		<div id='pic_2div'>
			<div id='background_animation1'>
			<i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
  <i></i>
				<p id='someText'>what the damn fuck </p>
			</div>	
			</div>
			<footer id='footer2'>This is the Footer</footer>
			</div>
			
	);
};

export default Blogs;*/

/*<p>{post.content}</p>*/