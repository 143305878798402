import React, { useState, useEffect } from 'react';
import '../App.css';

function BGanimation({ children }) {
    const [scrolling, setScrolling] = useState(false);
    const [scrollY, setScrollY] = useState(0);
  
    useEffect(() => {
      // Function to handle the scroll event and update the scroll position
      const handleScroll = () => {
        setScrolling(true);
        setScrollY(window.scrollY);
      };
  
      // Function to handle the end of scrolling
      const handleEndScroll = () => {
        setScrolling(false);
      };
  
      // Attach the scroll event listener
      window.addEventListener('scroll', handleScroll);
  
      // Attach an event listener for the end of scrolling
      window.addEventListener('mouseup', handleEndScroll);
      window.addEventListener('touchend', handleEndScroll);
  
      // Clean up the event listeners when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('mouseup', handleEndScroll);
        window.removeEventListener('touchend', handleEndScroll);
      };
    }, [scrolling]);
  
    return (
      <div className="container">
        <div className={`circle ${scrolling ? 'animate-circle' : ''}`} style={{ transform: `translateY(${scrollY * 0.3}px)` }}></div>
        <div className="content">{children}</div>
      </div>
    );
  }
  

export default BGanimation;