import React from "react";
import '../App.css';
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { useState, useEffect } from 'react'
//import Pic from '../images/ProximaCentauriLLClogo.jpeg';
import SignUp from '../pages/signup.js'; // Import the SignUp component
import Modal from 'react-modal';
import axios from 'axios';
import { useLocation } from 'react-router-dom';


const Navbar = () => {
	
	//const [isOpen, setIsOpen] = useState(false);
	//const [loggedIn, setLoggedIn] = useState(null);
	//const [username, setUsername] = useState('');
  const [showMenu, setShowMenu] = useState(false);

	const [loggedIn, setLoggedIn] = useState(() => {
        const storedToken = localStorage.getItem('accessToken');
        return !!storedToken;
    });

    const [username, setUsername] = useState(() => {
        const storedUsername = localStorage.getItem('username');
        return storedUsername || ''; // Set the username to an empty string if it's not present
    });

    useEffect(() => {
      // Scroll to the top when the component mounts
      window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this effect runs only once
  



    /*useEffect(() => {
      const handleBeforeUnload = async () => {
        try {
          // Implement your logout logic here
          await handleLogout();
        } catch (error) {
          console.error('Error during logout:', error);
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [loggedIn]); // Include loggedIn as a dependency*/
  

	
	console.log('navbar loggedin:', loggedIn)

	const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  //const handleNavbarLogin = (loggedInUser) => {
    // Update the state or perform any other actions based on the login
    //console.log(`Navbar received login notification for user: ${loggedInUser}`);
    //setLoggedIn(true);
    //setUsername(loggedInUser);
  
  //};

  useEffect(() => {
    const handleStorageChange = () => {
      // Update state when storage changes (user logs in)
      setLoggedIn(!!localStorage.getItem('accessToken'));
      setUsername(localStorage.getItem('username') || '');
    };

    // Add storage event listener
    window.addEventListener('storage', handleStorageChange);

    return () => {
      // Remove event listener on component unmount
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  


  const handleLogout = () => {
    //setLoggedIn(false);
	localStorage.removeItem('accessToken');
	localStorage.removeItem('username'); 
	console.log(`Logout successful. Goodbye, ${username}!`);
    window.alert(`Logout successful. Goodbye, ${username}!`);
	setLoggedIn(false); // Set the loggedIn state to null or an appropriate value
	setUsername('');
	delete axios.defaults.headers.common['Authorization'];

  };

  const location = useLocation();

  const [colorChange, setColorchange] = useState(false);

  useEffect(() => {
    setColorchange(window.scrollY >= 10);
  }, []);

  const changeNavbarColor = () => {
    setColorchange(window.scrollY >= 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavbarColor);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      // Ensure that username is set before rendering
      setUsername(localStorage.getItem('username') || '');
    }
  }, [loggedIn]);

	/*const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 10) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);*/

    const getCurrentPage = () => {
      const pathArray = location.pathname.split('/').filter(Boolean);
      const currentPage = pathArray.length > 0 ? pathArray[pathArray.length - 1] : 'default';
      console.log("Current Page Path:", location.pathname);
      console.log("Current Page Segments:", pathArray);
      console.log("Current Page:", currentPage);
      return currentPage;
  }

  const getLinkStyle = (page) => {
      const isScrolled = colorChange;
      const currentPage = getCurrentPage();
      const viewportWidth = window.innerWidth;

      const isActive = page === currentPage || (page === 'home' && currentPage === 'default');

      console.log(`Page: ${page}, Current Page: ${currentPage}, IsActive: ${isActive}`);

      if (isActive) {
          return null;
      }

      if (viewportWidth <= 1080) {
        return {
          color: 'white', // Set color to white for smaller viewports
        };
        }

      switch (currentPage) {
          case 'home':
              return {
                  color: isScrolled ? 'white' : 'black',
              };
          case 'contact':
              return {
                  color: isScrolled ? 'black' : 'white',
              };
          case 'blogs':
              return {
                  color: isScrolled ? 'black' : 'white',
              };
            case 'info':
              return {
                  color: isScrolled ? 'black' : 'white',
              };
              case 'sign-up':
              return {
                  color: isScrolled ? 'white' : 'white',
              };
          default:
              return {
                  color: isScrolled ? 'black' : 'white',
              };
      }
  };

  // Close the dropdown menu when the user navigates to a new page
  useEffect(() => {
    setShowMenu(false);
    }, [location]);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    console.log('Menu toggled:', !showMenu); 
  };

  const getBarColor = () => {
  const isScrolled = colorChange;
  const currentPage = getCurrentPage();
  


  
  switch (currentPage) {
    case 'home':
    return isScrolled ? 'white' : 'black';
    case 'contact':
    return isScrolled ? 'black' : 'white';
    case 'blogs':
    return isScrolled ? 'black' : 'white';
    case 'info':
    return isScrolled ? 'black' : 'white';
    case 'sign-up':
    return isScrolled ? 'black' : 'black';				
    default:
    return isScrolled ? 'black' : 'white';
  }
  };

  const barStyle = {
  backgroundColor: getBarColor(),
  };

  	return (
		<>
			<Nav className={`${colorChange ? 'navbar colorChange' : 'navbar'} ${showMenu ? 'active' : ''}`}>
      <div id='nav_div'>
				<NavMenu className={showMenu ? 'active' : ''}>
					<NavLink to="/" withShadow={!colorChange} activeClassName id="first_link">
          <div id="logo_div">
            
          </div>
					</NavLink>
          <NavLink to="/"  withShadow={!colorChange} activeClassName="active" style={getLinkStyle('home')}>
						Home
					</NavLink>
					
					<NavLink to="/info" withShadow={!colorChange} activeClassName="active" style={getLinkStyle('info')}>
						Info
					</NavLink>
          <NavLink to="/contact"  withShadow={!colorChange} activeClassName="active" style={getLinkStyle('contact')}>
						Contact Us
					</NavLink>
          {loggedIn && (
					<div id="signIn">
					  <button onClick={loggedIn ? handleLogout : openModal}>
									{loggedIn ? `Logout ${username}` : 'Sign Up/Login'}
      			</button>
      					<Modal isOpen={modalIsOpen}
        						onRequestClose={closeModal}
        						contentLabel="Sign Up Modal">
        				
						      <SignUp closeModal={closeModal} setLoggedIn={setLoggedIn} loggedIn={loggedIn} setUsername={setUsername} />
							    <button onClick={closeModal}>Close Modal</button>
      					</Modal>
    				</div>
          )}
				</NavMenu>
        </div>
			</Nav>
      <div className="hamburger"  onClick={toggleMenu} >
    					<span  style={barStyle} className="bar"></span>
    					<span  style={barStyle} className="bar"></span>
    					<span  style={barStyle} className="bar"></span>
					</div>
		</>
	);
};

export default Navbar;



// <NavLink to="/secure" activeStyle>
//Secure
//</NavLink>
//className={colorChange ? 'navbar colorChange' : 'navbar'} 
//onTouchStart={handleTouchToggle} 
//const handleTouchToggle = () => {
  //setShowMenu(prevState => !prevState);//setShowMenu(!showMenu); // Toggle the menu state
//};
//<img id='logo' src={Pic} alt=''/>
/*<NavLink to="/blogs" withShadow={!colorChange}  activeClassName="active" style={getLinkStyle('blogs')}>
						Blogs
					</NavLink>*/