import React, { useEffect, useState, useRef  } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
//import Pic13 from '../images/pic_13.jpg';
import Slideshow from './slideshow.js'
import SlideInDiv from './slider.js'
import SlideInDivLeft from './slider2.js'
import BGanimation from './BGanimation.js'
import Footer from './footer.js'
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useScrollBehavior } from './useScrollBehavior.js';


const Home = () => {
	const [isVisibleCenter, setIsVisibleCenter] = useState(false);
	const centerRef = useRef(null);
	const [isVisible, setIsVisible] = useState(false);
  	const footerDivRefs = useRef([]);
  	const location = useLocation();
  

  useScrollBehavior();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    footerDivRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      footerDivRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [location.pathname]);



	//useEffect(() => {
		// Scroll to the top when the component mounts
		//window.scrollTo(0, 0);
	  //}, []); // Empty dependency array ensures this effect runs only once
	
    
	useEffect(() => {
		const hash = window.location.hash.substring(1);
		if (hash) {
		  const targetElement = document.getElementById(hash);
		  if (targetElement) {
			targetElement.scrollIntoView({ behavior: 'smooth' });
		  }
		}
	  }, []);

	  const flipAnimation = {
		hidden: { rotateX: -90, opacity: 0 },
		visible: {
		  rotateX: [ -90, 0 ],
		  opacity: [0, 1],
		  transition: {
			duration: 1,
			ease: "easeInOut",
			delay: .3,  // Adjust this value for the desired delay
		  },
		},
	  };

	  const letterAnimation = {
		hidden: { opacity: 0 },
		visible: (i) => ({
		  opacity: 1,
		  transition: {
			delay: i * 0.05,
			duration: 0.5,
		  },
		}),
	  };

	  const AnimatedText = ({ text }) => {
		const characters = text.split('').map((char, i) => ({
		  char,
		  key: i
		}));
		return (
		  <div style={{ display: 'inline-block' }}>
			{characters.map(({ char, key }) => (
			  <motion.span
				id='texbox_1_text'
				key={key}
				custom={key}
				initial="hidden"
				animate="visible"
				variants={letterAnimation}
				style={{ display: 'inline-block', whiteSpace: char === ' ' ? 'pre' : 'normal' }}
			  >
				{char}
			  </motion.span>
			))}
		  </div>
		);
	  };

	  useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisibleCenter(true);
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (centerRef.current) {
            observer.observe(centerRef.current);
        }

        return () => {
            if (centerRef.current) {
                observer.unobserve(centerRef.current);
            }
        };
    }, []);
  
	
	
	return (
		<div id='index_div1'>
			<div id='index_pic_div'>
				<div id='pic_13'></div>
			</div>
			<div id='test'>
				<motion.div
        			id='index_div2'
        			variants={flipAnimation}
        i			nitial="hidden"
        			animate="visible"
      				>
        			<p>Prox Offensive Information Security</p>
      			</motion.div>
			</div>
			<div id='textbox_1'>
				<AnimatedText text="Connecting you with all your Cybersecurity needs"/>
			</div>
			
			<div id='spacer_div'>
				<div id='spacer_container' ref={centerRef}>
				<div  className={`center_indexdiv ${isVisibleCenter ? 'visible' : ''}`} 
								style={{ transitionDelay: '.5s' }}>
					<motion.h1
							id="explore-services-heading"
                            initial="hidden"
                            animate={isVisibleCenter ? "visible" : "hidden"}
                            variants={flipAnimation}
                        >Proactive Cybersecurity: Outpacing Threats Before They Strike
                        </motion.h1>
						<br></br>
					<p id='center_indexdivpara'> Relying solely on a defensive 
						cybersecurity approach can expose your assets to significant 
						risks. Undetected vulnerabilities and threats not only lead 
						to substantial financial losses but also erode stakeholder 
						confidence in your ability to protect sensitive information. 
						Prox Offensive Information Security takes a proactive stance with 
						offensive cybersecurity strategies. We 
						initiate controlled attack simulations to uncover and address 
						vulnerabilities in your systems before malicious actors can 
						exploit them.</p>
				</div>
				</div>
			</div>

			<Slideshow />
			
			<BGanimation>
			<SlideInDiv>
			<div id='textbox_2'>
			
				<div id='someText1'>
					<div id="someText_1">
						<p>Who We Are</p>	
					</div>
					<div class="separator2"></div>
					<div id='someTextsub1'>
						<p>Prox Offensive Information Security is an offensive cybersecurity team 
							at the forefront of technology, specializing in cyber- 
							attack simulation and advanced security assessment. We 
							provide true visibility into each of your asset’s level 
							of exposure and detect highly complex security risks that 
							no automatic vulnerability scanning process can identify.
						</p>
					</div>
					
				</div>
				<div id="someText_2">
						<div id="someTextsub_1">

						</div>
						
					</div>
			</div>
            </SlideInDiv>
			</BGanimation>
			<div id='index_info_div'>
			<div id='index_info_div_sub1'>
				<div id='index_info_div_sub1_heding'>Cybersecurity Best Practices and Hygiene</div>
				<div>Protecting You and Your Household</div>
			</div>
			<div id='index_info_div_sub2'>
				<div id='index_info_1'>
				<div className='index_info_heading'>Risk Assessment</div>
				<div className='index_info_subheading'>Optimized for All Devices</div>
				<div> Whether you’re 
					a tech-savvy professional or a casual internet user, understanding 
					your vulnerabilities is crucial. We evaluate factors such as the devices 
					you use (computers, smartphones, smart home gadgets), your online habits, 
					and potential threats to your tech ecosystem.</div>
				</div>	
				<br></br>	
				<div id='index_info_2'>
				<div className='index_info_heading'>Secure Password Management</div>
				<div className='index_info_subheading'>Strategic Insights and Innovative Solutions</div>
				<div>We guide you in creating strong, unique passwords for each account,
					recommending using password managers to securely store and manage credentials.
				</div>
				</div>			
			</div>
			<div id='index_info_div_sub3'>
				<div id='index_info_3'>
				<div className='index_info_heading'>Authentication (MFA)</div>
				<div className='index_info_subheading'>Seamless Online Sales</div>
				<div>We emphasize enabling MFA wherever possible. It adds an extra layer 
					 of security by requiring a second form of authentication 
					 (e.g., a text message or app notification) in addition to your password.</div>
				</div>	
				<br></br>
				<div id='index_info_4'>
				<div className='index_info_heading'>Home Network Security</div>	
				<div className='index_info_subheading'>We educate you on safe browsing habits:</div>
				<div>Change default router passwords. Use strong encryption (WPA2 or WPA3).
					  Segment your network (guest network vs. personal devices).
				</div>
				</div>	
			</div>
			</div>
            <SlideInDivLeft>
			<div id='textbox_3'>
			<div id="textbox_3_1">
						<div id="textbox_3_2">

						</div>
					</div>
			
				<div id='someText2'>
					
					<div id='someText2_heading'>
						<p>What is Offensive Cybersecurity?</p>
					</div>
					<div class="separator"></div> 
					<div id='someTextsub2'>
						<p>Cybersecurity encompasses all the actions companies take to safeguard their 
							IT assets from attacks. This includes both defensive and offensive measures.

							Offensive cybersecurity involves employing the same tools, tactics, and 
							techniques as real attackers. However, instead of causing harm, these methods 
							are used to strengthen an organization’s security posture. </p>
					</div>
				</div>
			</div>
            </SlideInDivLeft>
			<div id="bottom_index_container">
			<Link to='/info#info_box2'>
			<div id='index_div3' className={`slide-in-div-index ${isVisible ? 'visible' : ''}`} ref={(el) => footerDivRefs.current[0] = el}>
				<div id='indextextdiv1'>
					<h2>Penetration Testing</h2>
					<br></br>
					
					<p>    Reduce the risk of a breach within system infrastructure</p>
							
							<p>Identify gaps in processes and procedures</p>
							
							<p>Strengthen your cybersecurity posture</p>
							 
							<p>Highlight how a vulnerability can lead to compromise</p>

				</div>
			</div>
			</Link>
			<Link to='/info#info_box3'>
			<div id='index_div4' className={`slide-in-div-index ${isVisible ? 'visible' : ''}`} ref={(el) => footerDivRefs.current[1] = el}>
				<div id='indextextdiv2'>
					<h2>Application Security Testing</h2>
					<br></br>
					
					<p>    Determine your app’s security posture</p>
							
						<p>Find other vulnerabilities that may be missed in automation 
							testing alone</p>

							<p>Gain a realistic simulation of how an attacker would target an application</p>
				</div>
			</div>
			</Link>
			<Link to='/info#info_box4'>
			<div id='index_div5' className={`slide-in-div-index ${isVisible ? 'visible' : ''}`} ref={(el) => footerDivRefs.current[2] = el}>
				<div id='indextextdiv3'>
					<h2>Mentoring</h2>
					<br></br>
					
					<p>Are you passionate about cybersecurity and eager to 
							dive into the world of ethical hacking, threat analysis, 
							and network defense? Prox Offensive Information 
							Security offers personalized mentorship to budding cybersecurity 
							enthusiasts like you.</p>
				</div>
			</div>
			</Link>
			</div>
			<Footer/>
		</div>

		
	);
};

export default Home;

//We begin by assessing your unique risk profile.
//<img id='pic_13' src={Pic13} alt="Pic" />