import React, { useState, useEffect  } from 'react';
import axios from 'axios'; // Import axios
import '../App.css';
import ReCAPTCHA from 'react-google-recaptcha';


function ContactForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    serviceOfInterest: '',
    message: '',
    recaptchaToken: '',
  });

  //const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRecaptcha = async () => {
    try {
      const token = await window.grecaptcha.execute();
      setFormData((prevState) => ({
        ...prevState,
        recaptchaToken: token,
      }));
    } catch (error) {
      console.error('Error executing reCAPTCHA:', error);
    }
  };

  /*const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };*/

  // Handle reCAPTCHA token
  /*const handleRecaptchaChange = (token) => {
    setFormData((prevState) => ({
      ...prevState,
      recaptchaToken: token,
    }));
  };*/


  const handleSubmit = async (e) => {
    e.preventDefault();

    await handleRecaptcha();

    // Ensure the reCAPTCHA token is set
    if (!formData.recaptchaToken) {
      alert('Please Push " Contact Us" Again');
      return;
    }
    
    try {
      // Send form data to the server
      const response = await axios.post('https://tf80t2thxf.execute-api.us-east-1.amazonaws.com/prod/contact', formData);


      // Handle the response as needed
      console.log('Server Response:', response.data);

      // Reset the form fields
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        company: '',
        serviceOfInterest: '',
        message: '',
        recaptchaToken: '',
      });

      alert('Your information was submitted successfully!');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };


  

  return (
    <div id='formcontainer'>
      <div id='contact_form_title' style={{paddingTop: '60px'}}><h2 style={{fontSize: '35px'}}>Contact Us</h2></div>
      
      <form onSubmit={handleSubmit}>
        <div id='firstnamediv' className="form-group">
        <label htmlFor="firstName">First Name:</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '2px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='lastnamediv' className="form-group">
        <label htmlFor="lastName" >Last Name:</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '2px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='emaildiv' className="form-group">
          <label htmlFor="email" >Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='phonediv' className="form-group">
         <label htmlFor="phoneNumber" >Phone Number:</label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            style={{ width: '90%', padding: '10px', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='companydiv' className="form-group">
          <label htmlFor="company" >Company:</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%',
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='servdiv' className="form-group">
        <label htmlFor="serviceOfInterest" >Service of Interest:</label>
          <input
            type="text"
            id="serviceOfInterest"
            name="serviceOfInterest"
            value={formData.serviceOfInterest}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%', 
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          />
        </div>
        <div id='messagediv' className="form-group">
          <label htmlFor="message" >Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            style={{ width: '94.5%', padding: '10px', marginLeft: '1%',
            border: '1px solid #ccc', borderRadius: '5px', fontSize: '14px' }}
          ></textarea>
        </div>

        <ReCAPTCHA id='recaptcha'
          sitekey="6LdKBD8qAAAAADj9VIHt8ez78vs1idgN1RHtSKDp" 
          size="invisible"
          onChange={handleRecaptcha}
        />

        <button id='formbutton' type="submit">Contact US</button>
      </form>
    </div>
  );
}

export default ContactForm;



/*const handleSubmit = (e) => {
    e.preventDefault();
    // You can handle form submission logic here, e.g., sending data to a server
    alert(`Form data submitted: 
    \nFirst Name: ${formData.firstName}
    \nLast Name: ${formData.lastName}
    \nEmail: ${formData.email}
    \nPhone Number: ${formData.phoneNumber}
    \nCompany: ${formData.company}
    \nService of Interest: ${formData.serviceOfInterest}
    \nMessage: ${formData.message}`);
    console.log('Form data submitted:', formData);

    // Reset the form fields
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '', 
      company: '',
      serviceOfInterest: '',
      message: '',
    });
  };*/
