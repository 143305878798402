//import React from 'react';
//import SlideInDiv from './slider'
//import SlideInDivLeft from './slider2'

//dashboard
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import withAuth from './withAuth'; // Import the withAuth HOC
import Pic11 from '../images/pic_11.jpg'


const Secure = () => {

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {

    // Check if the user is authenticated
    const token = localStorage.getItem('accessToken');
    console.log('First Token in secure:',token);
    console.log('Token before request:', token);
    

    if (!token) {
      //console.log('Redirecting to /sign-up');
      // Redirect to login page if not authenticated
      navigate('/sign-up');//history.push('/login');
      return;
    }

    axios.get('http://localhost:5001/api/secure', {
  headers: {
    Authorization: `Bearer ${token}`,
  },
})
  .then((response) => {
    // Handle the response from the secure endpoint
    console.log(response.data);
  })
  .catch((error) => {
    // Handle errors
    console.error(error);

    // If the server returns a 403 status, it means the user is not authenticated
    if (error.response && error.response.status === 403) {
      console.log('Redirecting to /sign-up');
      console.log('Token in secure:', token);
      navigate('/sign-up');
    } else {
      // Handle other errors
      console.error(error);
    }

  });

    // Fetch the users list from the server
    axios.get('http://localhost:5001/api/admin/users/', {  // axios.get('http://localhost:5001/api/admin/users')  //axios.get('/api/admin/users')
    
      headers: {
        Authorization: `Bearer ${token}`,
      },
      
    })        
      .then((response) => {
        setUsers(response.data);
        console.log('response.data.message:',response.data.message);
      })
      .catch((error) => {
        // If the server returns a 403 status, it means the user is not authenticated
        if (error.response && error.response.status === 403) {
          console.log('Redirecting to /sign-up from secure');
          console.log('Token in secure:',token);
          navigate('/sign-up');
        } else {
          // Handle other errors
          console.error(error);
        }
      });
  }, [navigate]);
      /*.catch((error) => {
        // Handle error
        console.error(error);
        
      });
  }, [navigate]);*/

  const deleteUser = (userId) => {
    const token = localStorage.getItem('accessToken');

    axios.delete(`http://localhost:5001/api/admin/users/${userId}`, { // axios.delete(`http://localhost:5001/api/admin/users/${userId}`)
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        // Handle deletion
        console.log(response.data.message);
        // Refresh users list or update state after deletion
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  };

  return (
    <div id="admin">
      
       <div id='admin_picdiv'>
          <img id='adim_pic' src={Pic11} alt=''/>
        
        <div id='admin_central_div'>
        <div id='admin_div1'>
            <h1>Admin Dashboard</h1>
        </div>
        <div id='admin_div2'> 
        
        <h2>User Management</h2>
          <ul>
            {users.map((user) => (
              <li key={user.id}>
                <span>{user.id}</span>
                <span>{user.username}</span>
                {/* Additional controls and actions for each user */}
                <button onClick={() => deleteUser(user.id)}>Delete</button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
    </div>
  );

};

export default withAuth(Secure, { redirect: true });


//};

//export default AdminDashboard;

	/*return (
		<div id='secure_div1'>
			<h1>
				GeeksforGeeks is a Computer
				Science portal for geeks.
			</h1>
			<div id='textbox_4'>
			<SlideInDivLeft>
				<div id='secdiv_slide1'>
					<p id='someText'>what the damn fuck </p>
				</div>
			</SlideInDivLeft>
				
			</div>
			<SlideInDiv>
			<div id='secdiv_slide2'>
				<p id='someText'>what the damn fuck </p>
			</div>
			</SlideInDiv>
			

		</div>
	);*/